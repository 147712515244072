// ** React Imports
import React, { type InputHTMLAttributes, } from "react"
// ** Store & Actions
// ** Third Party Components
import { Form, InputGroup, } from "react-bootstrap"
import { Typeahead, } from "react-bootstrap-typeahead"
import Ttypeahead from "react-bootstrap-typeahead/types/core/Typeahead"
import { type FieldErrors, type Control, } from "react-hook-form"
// ** Custom Components
import PasswordInput from "./PasswordInput"
// ** Hooks, context & utils
import { useVerticalForm, } from "utility/context/VerticalForm"
// ** Conf & helpers
// ** Styles
// ** Images


interface ErrorMessageProps {
  error?: string
  className?: string
}

const ErrorMessage = ({
  error,
  className,
}: ErrorMessageProps): JSX.Element | null => {
  if (error === undefined) return null
  return (
    <Form.Control.Feedback type="invalid" className={className}>
      {error}
    </Form.Control.Feedback>
  )
}

// interface ControllerComponentProps {
//   name: string
//   children: React.ReactNode
//   controller: boolean
//   control?: Control<any>
// }
// const ControllerComponent = ({ name, children, controller, control, }: ControllerComponentProps): JSX.Element => {
//   if (!controller) return <>children</>
//   return (
//     <Controller
//       name={name}
//       control={control}
//       render={({ field: { onChange, onBlur, }, }) => (
//         <ReactDatePicker
//           onChange={onChange}
//           onBlur={onBlur}
//           selected={value}
//         />
//       )}
//     />
//   )
// }

type FormInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string | { trueVal: string, falseVal: string }
  type?: string
  name: string
  placeholder?: string
  register?: any
  registerOptions?: Record<string, any>
  errors?: FieldErrors
  controller?: boolean
  control?: Control<any>
  className?: string
  labelClassName?: string
  containerClass?: string
  // ref?: React.RefObject<unknown>
  _ref?: React.MutableRefObject<any>
  // refCallback?: any
  children?: React.ReactNode
  rows?: number
  autoComplete?: string
  readonly watchedValue?: any
  Button?: React.ElementType
  // selectMenu
  clearButton?: boolean
  multiple?: boolean
  options?: Array<string | Record<string, any>>
  emptyLabel?: string
  labelKey?: string | ((option: string | Record<string, any>) => string)
  // disabled?: boolean
  defaultInputValue?: string
}

const FormInput = ({
  label,
  type,
  name,
  placeholder,
  register,
  registerOptions,
  errors,
  // controller = false,
  control,
  className,
  labelClassName,
  containerClass,
  // ref,
  _ref,
  // refCallback,
  children,
  rows,
  autoComplete,
  watchedValue,
  Button,
  // selectMenu
  clearButton = false,
  multiple = false,
  options = [],
  labelKey,
  emptyLabel = "Aucune correspondance",
  // disabled = false,
  defaultInputValue,

  ...otherProps
}: FormInputProps): JSX.Element => {
  const {
    formState,
    setValue,
    trigger,
  } = useVerticalForm()

  // handle input type
  const comp = type === "textarea" ? "textarea" : type === "select" ? "select" : "input"

  const error = name.split(".").reduce((accumulator, currentValue): Record<string, any> | undefined => accumulator?.[currentValue], errors)

  switch (type) {
    case "hidden":
      return <input type={type} name={name} {...(register !== undefined ? register(name, registerOptions) : {})} {...otherProps} />
    case "password":
      return (
        <>
          <Form.Group className={containerClass}>
            {
              label !== undefined &&
                <>
                  {" "}
                  <Form.Label className={labelClassName}>{label as string}</Form.Label> {children}{" "}
                </>
            }
            <PasswordInput
              name={name}
              placeholder={placeholder}
              _ref={_ref}
              // refCallback={refCallback}
              errors={errors}
              register={register}
              className={className}
              autoComplete={autoComplete}
            />
            <ErrorMessage
              error={error?.message as unknown as string}
              className="d-block"
            />
          </Form.Group>
        </>
      )
    case "select":
      return (
        <Form.Group className={containerClass}>
          {label !== undefined && <Form.Label className={labelClassName}>{label as string}</Form.Label>}
          <Form.Select
            type={type}
            label={label}
            name={name}
            id={name}
            _ref={_ref}
            // ref={(r: HTMLInputElement) => {
            //   if (refCallback !== undefined) refCallback(r)
            // }}
            comp={comp}
            className={className}
            isInvalid={error !== undefined}
            {...(register !== undefined ? register(name, registerOptions) : {})}
            {...otherProps}
          >
            {children}
          </Form.Select>
          <ErrorMessage
            error={error?.message as unknown as string}
          />
        </Form.Group>
      )
    case "selectMenu":
      return (
        <Form.Group className={containerClass}>
          {label !== undefined && <Form.Label className={labelClassName}>{label as string}</Form.Label>}
          <Typeahead
            id={name}
            ref={_ref as unknown as React.MutableRefObject<Ttypeahead>}
            multiple={multiple}
            onChange={(selected) => {
              setValue?.(name, multiple ? selected : selected[0])
              if (formState?.isSubmitted === true) void trigger?.(name)
            }}
            options={options}
            labelKey={labelKey}
            placeholder={placeholder}
            emptyLabel={emptyLabel}
            disabled={otherProps.disabled}
            defaultInputValue={defaultInputValue}
            isInvalid={error !== undefined}
            // {...(register !== undefined ? register(name, registerOptions) : {})}
            clearButton={clearButton}
          />
          <ErrorMessage
            error={error?.message as unknown as string}
          />
        </Form.Group>
      )
    case "switch":
      return (
        <Form.Group className={containerClass}>
          <Form.Check
            type="switch"
            label={typeof label === "object" ? (watchedValue === true ? label.trueVal : label.falseVal) : label}
            name={name}
            id={name}
            _ref={_ref}
            // ref={(r: HTMLInputElement) => {
            //   if (refCallback !== undefined) refCallback(r)
            // }}
            isInvalid={error !== undefined}
            // isInvalid={errors?.[name] !== undefined}
            {...(register !== undefined ? register(name, registerOptions) : {})}
            {...otherProps}
          />
          <ErrorMessage
            error={error?.message as unknown as string}
            // error={errors?.[name]?.message as string}
            className="d-block"
          />
        </Form.Group>
      )
    case "checkbox":
    case "radio":
      return (
        <Form.Group className={containerClass}>
          <Form.Check
            label={label}
            type={type}
            name={name}
            id={name}
            // ref={ref}
            _ref={_ref}
            // ref={(r: HTMLInputElement) => {
            //   if (refCallback !== undefined) refCallback(r)
            // }}
            className={className}
            isInvalid={error !== undefined}
            // isInvalid={errors?.[name] !== undefined}
            {...(register !== undefined ? register(name, registerOptions) : {})}
            {...otherProps}
          />
          <ErrorMessage
            error={error?.message as unknown as string}
            // error={errors?.[name]?.message as string}
            className="d-block"
          />
        </Form.Group>
      )
    default:
      return (
        <Form.Group className={containerClass}>
          {label !== undefined && <Form.Label className={labelClassName}>{label as string}</Form.Label>}

          <InputGroup className="mb-0">
            <Form.Control
              type={type}
              placeholder={placeholder}
              name={name}
              id={name}
              as={comp}
              _ref={_ref}
              // ref={(r: HTMLInputElement) => {
              //   if (refCallback !== undefined) refCallback(r)
              // }}
              className={className}
              isInvalid={error !== undefined}
              {...(register !== undefined ? register(name, registerOptions) : {})}
              {...(rows !== undefined && { rows, })}
              {...otherProps}
              autoComplete={autoComplete ?? name}
            >
              {children ?? null}
            </Form.Control>
            {Button !== undefined && <Button />}
            <ErrorMessage
              error={error?.message as unknown as string}
              className="d-block"
            />
          </InputGroup>
        </Form.Group>
      )
  }
}

export default FormInput

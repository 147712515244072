// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
import { BrowserRouter, useRoutes, } from "react-router-dom"
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Styles
// ** Images

import allRoutes from "./routes/allRoutes"

const Router = (): JSX.Element => {
  const Routes = (): React.ReactElement<any, string | React.JSXElementConstructor<any>> | null => useRoutes(allRoutes)

  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  )
}

export default Router

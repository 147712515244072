// ** React Imports
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Styles
// ** Images


export const defaults = {
  name: "Avina",
  defaultUrl: "agenda",
  offCanvasWidth: "1100px",
  needSheetDeadlineDelay: 10,
  // routerTransition: "fadeIn", // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
  calendar: {
    onlyPending: false,
    accomplishedStyle: {
      backgroundColor: "#d6d6d6",
      borderColor: "#808080",
      color: "#000000",
    },
  },
}

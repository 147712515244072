// ** React Imports
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Styles
// ** Images

/* Layout types and other constants */
export enum LayoutColors {
  LAYOUT_COLOR_LIGHT = "light",
  LAYOUT_COLOR_DARK = "dark",
}
// export type LayoutColor = "light" | "dark"

export enum LayoutTypes {
  LAYOUT_VERTICAL = "vertical",
  LAYOUT_HORIZONTAL = "topnav",
  LAYOUT_DETACHED = "detached",
  LAYOUT_FULL = "full",
}
// export type LayoutType = "vertical" | "topnav" | "detached" | "full"

export enum LayoutWidthes {
  LAYOUT_WIDTH_FLUID = "fluid",
  LAYOUT_WIDTH_BOXED = "boxed",
}
// export type LayoutWidth = "fluid" | "boxed"

export enum SideBarThemes {
  LEFT_SIDEBAR_THEME_DEFAULT = "default",
  LEFT_SIDEBAR_THEME_LIGHT = "light",
  LEFT_SIDEBAR_THEME_DARK = "dark",
}
// export type SideBarTheme = "default" | "light" | "dark"

export enum SideBarWidthes {
  LEFT_SIDEBAR_TYPE_FIXED = "fixed",
  LEFT_SIDEBAR_TYPE_CONDENSED = "condensed",
  LEFT_SIDEBAR_TYPE_SCROLLABLE = "scrollable",
}
// export type SideBarWidth = "fixed" | "condensed" | "scrollable"

// export interface Layout {
//   layoutType: LayoutTypes
//   layoutColor: LayoutColors
//   layoutWidth: LayoutWidthes
//   leftSideBarTheme: SideBarThemes
//   leftSideBarType: SideBarWidthes
//   showRightSidebar: boolean
// }

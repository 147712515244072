// ** React Imports
import React, { createContext, useContext, } from "react"
// ** Store & Actions
// ** Third Party Components
import { type AnySchema, } from "joi"
import {
  type FieldValues,
  type DefaultValues,
  type SubmitHandler,
  type SubmitErrorHandler,
  type UseFormRegister,
  type UseFormWatch,
  type UseFormSetValue,
  type UseFormGetValues,
  type UseFormTrigger,
  type UseFormReset,
  type FieldErrorsImpl,
  type Control,
} from "react-hook-form"
// ** Custom Components
// ** Hooks, context & utils
import { useReactHookForm, } from "hooks/useReactHookForm"
// ** Conf & helpers
// ** Styles
// ** Images


interface VerticalVerticalFormContextProps<TFormValues extends FieldValues> {
  readonly register?: UseFormRegister<TFormValues>
  readonly formState?: {
    dirtyFields: Partial<Readonly<Record<string, any>>>
    touchedFields: Partial<Readonly<Record<string, any>>>
    defaultValues: TFormValues
    isSubmitted: boolean
    isSubmitSuccessful: boolean
    submitCount: number
    isValid: boolean
    errors: Partial<FieldErrorsImpl<Record<string, any>>>
  }
  readonly watch?: UseFormWatch<TFormValues>
  readonly reset?: UseFormReset<TFormValues>
  readonly setValue?: UseFormSetValue<TFormValues>
  readonly getValues?: UseFormGetValues<TFormValues>
  readonly trigger?: UseFormTrigger<TFormValues>
  readonly control?: Control<TFormValues, any>
}

const VerticalFormContext = createContext({})

const useVerticalForm = <TFormValues extends FieldValues = FieldValues>(): VerticalVerticalFormContextProps<TFormValues> => useContext(VerticalFormContext)

interface VerticalFormProviderProps<TFormValues extends FieldValues> {
  id: string
  schema: AnySchema
  context?: object
  initialValues: DefaultValues<TFormValues>
  onSubmit: SubmitHandler<TFormValues>
  onError?: SubmitErrorHandler<TFormValues>
  formClass?: string
  children?: React.ReactNode
}

const VerticalFormProvider = <TFormValues extends FieldValues = FieldValues>(props: VerticalFormProviderProps<TFormValues>): JSX.Element => {
  const {
    id,
    schema,
    context,
    initialValues,
    onSubmit,
    onError,
    formClass,
    children,
  } = props

  const {
    register,
    // unregister,
    formState,
    watch,
    handleSubmit,
    reset,
    // resetField,
    // setError,
    // clearErrors,
    setValue,
    // setValues,
    // setFocus,
    getValues,
    // getFieldState,
    trigger,
    control,
  } = useReactHookForm<TFormValues>(
    schema,
    context ?? {},
    initialValues,
    onSubmit,
    onError
  )

  return (
    <VerticalFormContext.Provider value={{
      register,
      formState,
      watch,
      reset,
      setValue,
      getValues,
      trigger,
      control,
    }}>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form id={id} onSubmit={handleSubmit} className={formClass} noValidate>
        {Array.isArray(children)
          ? children.map(child => child)
          : children}
      </form>
    </VerticalFormContext.Provider>
  )
}

export { useVerticalForm, VerticalFormProvider, }

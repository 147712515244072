// ** React Imports
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import { LayoutTypes, LayoutColors, LayoutWidthes, SideBarThemes, SideBarWidthes, } from "./types/Layout"
// ** Styles
// ** Images


export const defaults = {
  layoutType: LayoutTypes.LAYOUT_VERTICAL,
  layoutColor: LayoutColors.LAYOUT_COLOR_LIGHT,
  layoutWidth: LayoutWidthes.LAYOUT_WIDTH_FLUID,
  leftSideBarTheme: SideBarThemes.LEFT_SIDEBAR_THEME_DARK,
  leftSideBarType: SideBarWidthes.LEFT_SIDEBAR_TYPE_FIXED,
  rightSidebarVisible: false,
}

// ** React Imports
import React, { Suspense, useCallback, useEffect, } from "react"
// ** Store & Actions
// ** Third Party Components
import { Container, } from "react-bootstrap"
import { Outlet, } from "react-router-dom"
// ** Custom Components
import ComponentSpinner from "components/spinner/ComponentSpinner"
// ** Hooks, context & utils
import useViewport from "hooks/useViewPort"
import useToggle from "hooks/useToggle"
import { changeBodyAttribute, } from "utility/helpers/layout"
import { useLayout, } from "utility/context/Layout"
// ** Conf & helpers
import { LayoutTypes, SideBarThemes, SideBarWidthes, } from "conf/types/Layout"
// ** Styles
// ** Images


// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(async () => await import("./topbar/Topbar"))
const LeftSidebar = React.lazy(async () => await import("./LeftSidebar"))
const Footer = React.lazy(async () => await import("./Footer"))
const RightSidebar = React.lazy(async () => await import("./RightSidebar"))

const VerticalLayout = (): JSX.Element => {
  const { width, } = useViewport()
  const [ isMenuOpened, toggleMenu, ] = useToggle()

  const {
    layoutWidth,
    leftSideBarTheme,
    leftSideBarType,
    changeSidebarType,
    disableLayer: {
      isDisabled,
    },
  } = useLayout()

  /*
   * layout defaults
   */
  useEffect(() => {
    changeBodyAttribute("data-layout", LayoutTypes.LAYOUT_VERTICAL)
  }, [])

  useEffect(() => {
    changeBodyAttribute("data-layout-mode", layoutWidth)
  }, [ layoutWidth, ])

  useEffect(() => {
    changeBodyAttribute("data-leftbar-theme", leftSideBarTheme)
  }, [ leftSideBarTheme, ])

  useEffect(() => {
    changeBodyAttribute("data-leftbar-compact-mode", leftSideBarType)
  }, [ leftSideBarType, ])

  /**
   * Open the menu when having mobile screen
   */
  const openMenu = (): void => {
    toggleMenu()

    if (document.body !== undefined) {
      if (isMenuOpened) {
        document.body.classList.remove("sidebar-enable")
      } else {
        document.body.classList.add("sidebar-enable")
      }
    }
  }

  const updateDimensions = useCallback(() => {
    // activate the condensed sidebar if smaller devices like ipad or tablet
    if (width > 768 && width <= 1028) {
      changeSidebarType(SideBarWidthes.LEFT_SIDEBAR_TYPE_CONDENSED)
    } else if (width > 1028) {
      changeSidebarType(SideBarWidthes.LEFT_SIDEBAR_TYPE_FIXED)
    }
  }, [ width, ])

  useEffect(() => {
    window.addEventListener("resize", updateDimensions)

    return () => {
      window.removeEventListener("resize", updateDimensions)
    }
  }, [ updateDimensions, ])

  const isCondensed = leftSideBarType === SideBarWidthes.LEFT_SIDEBAR_TYPE_CONDENSED
  const isLight = leftSideBarTheme === SideBarThemes.LEFT_SIDEBAR_THEME_LIGHT

  return (
    <>
      <div className="wrapper">
        {isDisabled && <ComponentSpinner classname="size-sm" />}
        <Suspense fallback={<ComponentSpinner />}>
          <LeftSidebar isCondensed={isCondensed} isLight={isLight} hideUserProfile={true} />
        </Suspense>
        <div className="content-page">
          <div className="content">
            <Suspense fallback={<ComponentSpinner />}>
              <Topbar openLeftMenuCallBack={openMenu} hideLogo={true} />
            </Suspense>
            <Container fluid>
              <Outlet />
            </Container>
          </div>

          <Suspense fallback={<ComponentSpinner />}>
            <Footer />
          </Suspense>
        </div>
      </div>

      <Suspense fallback={<ComponentSpinner />}>
        <RightSidebar />
      </Suspense>
    </>
  )
}
export default VerticalLayout

// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
import { Link, } from "react-router-dom"
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Styles
// ** Images
import locksImg from "assets/images/locks.svg"

const AccessDenied = (): JSX.Element => {
  return (
    <div className="text-center">
      <img src={locksImg} height="120" alt="" />
      <h4 className="text-uppercase text-danger mt-3">Accés interdit</h4>
      <p className="text-muted mt-3">
        Vos droits ne sont pas suffisants pas pour afficher cette page.
      </p>

      <Link className="btn btn-info mt-3" to="/">
        <i className="mdi mdi-reply"></i> Retour à l&apos;accueil
      </Link>
    </div>
  )
}

export default AccessDenied

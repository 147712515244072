// ** React Imports
import React, { lazy, } from "react"
// ** Store & Actions
// ** Third Party Components
import { Navigate, type RouteObject, } from "react-router-dom"
// ** Custom Components
import LayoutWrapper from "../LayoutWrapper"
// ** Hooks, context & utils
import { useApp, } from "utility/context/App"
// ** Conf & helpers
// ** Styles
// ** Images


// ** Routes Imports
// import testsRoutes from "./testsRoutes"


// lazy load all the views

// Admin
const TaskList = lazy(async () => await import("views/task/List"))
const SentenceList = lazy(async () => await import("views/sentence/List"))
const StatusList = lazy(async () => await import("views/status/List"))

// Agenda
const Calender = lazy(async () => await import("views/calendar/Calendar"))

// Contact
const StandbyContact = lazy(async () => await import("views/contact/ListStandby"))
const ContactTodos = lazy(async () => await import("views/contact/Todos"))

// Customers
const CompanyList = lazy(async () => await import("views/company/List"))
const ContactList = lazy(async () => await import("views/contact/List"))

// Need sheets
const NeedSheetList = lazy(async () => await import("views/needSheet/List"))
const NeedSheetTemplatesList = lazy(async () => await import("views/needSheet/TemplateList"))
const NeedSheetSearch = lazy(async () => await import("views/needSheet/Search"))

// Users
const UserAccount = lazy(async () => await import("views/user/Account"))
const UserTeams = lazy(async () => await import("views/user/Teams"))
const UserList = lazy(async () => await import("views/user/List"))



const Root = (): JSX.Element => {
  const { defaultUrl, } = useApp()

  return <Navigate to={`/${defaultUrl}`} />
}

// ** Merge Routes
const allRoutes: RouteObject[] = [
  { path: "/", element: <Root />, },

  {
    path: "/",
    element: <LayoutWrapper publicRoute={false} restriction={{ admin: true, businessLeader: true, businessTeam: true, recruitmentTeam: true, }} />,
    children: [
      {
        path: "agenda",
        element: <Calender />,
      },
      {
        path: "/contacts/taches",
        element: <ContactTodos />,
      },
      {
        path: "liste-contacts",
        element: <ContactList />,
      },
      {
        path: "liste-societes",
        element: <CompanyList />,
      },
      {
        path: "mon-compte",
        element: <UserAccount />,
      },
      {
        path: "fiches-de-besoin",
        children: [
          {
            path: "",
            element: <NeedSheetList />,
          },
          {
            path: "recherche",
            element: <NeedSheetSearch />,
          },
          {
            path: "models",
            element: <NeedSheetTemplatesList />,
          },
        ],
      },
    ],
  },
  {
    path: "/",
    element: <LayoutWrapper publicRoute={false} restriction={{ admin: true, businessLeader: true, businessTeam: true, }} />,
    children: [
      {
        path: "liste-contacts-veille",
        element: <StandbyContact />,
      },
    ],
  },
  {
    path: "/",
    element: <LayoutWrapper publicRoute={false} restriction={{ admin: true, }} />,
    children: [
      {
        path: "gestion-taches",
        element: <TaskList />,
      },
      {
        path: "liste-utilisateurs",
        element: <UserList />,
      },
      {
        path: "phrases",
        element: <SentenceList />,
      },
      {
        path: "fiches-de-besoin/status",
        element: <StatusList />,
      },
    ],
  },
  {
    path: "/",
    element: <LayoutWrapper publicRoute={false} restriction={{ businessLeader: true, }} />,
    children: [
      {
        path: "equipe",
        element: <UserTeams />,
      },
    ],
  },




  // Tests
  // ...testsRoutes,

  // 404: Page not found
  {
    path: "*",
    element: <LayoutWrapper publicRoute={true} error={404} />,
  },
]

export default allRoutes

// ** React Imports
import { useState, useEffect, } from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Styles
// ** Images

export default function useViewport(): { width: number, height: number } {
  const [ width, setWidth, ] = useState<number>(window.innerWidth)
  const [ height, setHeight, ] = useState<number>(window.innerHeight)

  useEffect(() => {
    const handleWindowResize = (): void => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }

    window.addEventListener("resize", handleWindowResize)
    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])
  return { width, height, }
}

// ** React Imports
import React, { StrictMode, Suspense, } from "react"
// ** Store & Actions
// ** Third Party Components
import ReactDOM from "react-dom/client"
import { ErrorBoundary, } from "react-error-boundary"
import { ToastContainer, } from "react-toastify"
// ** Custom Components
import App from "./App"
import ErrorFallback from "components/errorBoundary/ErrorBoundary"
import FallbackSpinner from "components/spinner/FallbackSpinner"
// ** Hooks, context & utils
import { AppProvider, } from "utility/context/App"
import { AuthProvider, } from "./utility/context/Auth"
import { CalendarProvider, } from "./utility/context/Calendar"
import { LayoutProvider, } from "./utility/context/Layout"
// ** Conf & helpers
// ** Styles
import "react-toastify/dist/ReactToastify.css"
import "animate.css"
// ** Images


import reportWebVitals from "./reportWebVitals"

const rootElement = document.getElementById("root")
if (rootElement !== null) {
  const root = ReactDOM.createRoot(rootElement)

  root.render(
    <StrictMode>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Suspense fallback={<FallbackSpinner />}>
          <AppProvider>
            <AuthProvider>
              <CalendarProvider>
                <LayoutProvider>
                  <>
                    <App />
                    <ToastContainer
                      position="top-right"
                      autoClose={3000}
                      hideProgressBar={false}
                      newestOnTop
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    />
                  </>
                </LayoutProvider>
              </CalendarProvider>
            </AuthProvider>
          </AppProvider>
        </Suspense>
      </ErrorBoundary>
    </StrictMode>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

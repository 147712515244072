// ** React Imports
import React, { useEffect, } from "react"
// ** Store & Actions
// ** Third Party Components
import { Link, } from "react-router-dom"
import { Row, Col, Card, } from "react-bootstrap"
// ** Custom Components
import VerticalLayout from "layouts/Vertical"
import HorizontalLayout from "layouts/Horizontal/HorizontalLayout"
import DetachedLayout from "layouts/Detached"
import FullLayout from "layouts/Full"
import AccessDenied from "components/AccessDenied"
import PageNotFound from "components/PageNotFound"
import LoginForm from "components/LoginForm"
// ** Hooks, context & utils
import { changeBodyAttribute, } from "utility/helpers/layout"
import { useLayout, } from "utility/context/Layout"
import { useAuth, } from "utility/context/Auth"
// ** Conf & helpers
import type { Role, User, } from "conf/types/User"
import { LayoutTypes, } from "conf/types/Layout"
// ** Styles
// ** Images
import Logo from "assets/images/logo.png"

const getLayout = (layout: LayoutTypes): React.ComponentType => {
  switch (layout) {
    case LayoutTypes.LAYOUT_HORIZONTAL:
      return HorizontalLayout
    case LayoutTypes.LAYOUT_DETACHED:
      return DetachedLayout
    case LayoutTypes.LAYOUT_FULL:
      return FullLayout
    default:
      return VerticalLayout
  }
}

const checkAuthorisation = (user: User | null, restriction?: Role): boolean => {
  if (restriction === undefined) return true
  if (user === null) return false
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  return Object.keys(restriction).some(el => restriction[el as keyof typeof restriction] && user.role[el as keyof typeof restriction])
}

const FirstPlanLayout = ({ children, }: { children: JSX.Element }): JSX.Element => {
  return (
    <div className="position-absolute w-100 d-block top-0 start-0 authentication-bg" style={{ zIndex: 9999, }}>
      <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
        <div className="container">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5} xxl={4}>
              <Card>
                {/* logo */}
                <Card.Header className="pt-4 pb-4 text-center bg-primary">
                  <Link to="/">
                    <span>
                      <img src={Logo} alt="" height="48" />
                    </span>
                  </Link>
                </Card.Header>

                <Card.Body className="p-4">
                  {children}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

interface FirstPlanProps {
  user: User | null
  children?: JSX.Element
  publicRoute?: boolean
  restriction?: Role
  error?: number
}
const FirstPlan = ({ user, children, publicRoute, restriction, error, }: FirstPlanProps): JSX.Element | null => {
  // Error
  if (typeof error === "number") return <FirstPlanLayout><PageNotFound /></FirstPlanLayout>
  // Not public routes and user not connected
  if (publicRoute !== true && user === null) return <FirstPlanLayout><LoginForm /></FirstPlanLayout>
  // Check if user connected is authorized
  if (!checkAuthorisation(user, restriction)) return <FirstPlanLayout><AccessDenied /></FirstPlanLayout>
  return null
}

interface LayoutWrapperProps {
  publicRoute?: boolean
  restriction?: Role
  error?: number
  layout?: LayoutTypes
}
const LayoutWrapper = ({
  publicRoute,
  restriction,
  error,
  layout,
}: LayoutWrapperProps): JSX.Element => {
  const { connectedUser, } = useAuth()

  const {
    layoutType,
    layoutColor,
  } = useLayout()

  const Layout = getLayout(layout ?? layoutType)

  useEffect(() => {
    changeBodyAttribute("data-layout-color", layoutColor)
  }, [ layoutColor, ])

  return (
    <>
      <Layout />
      <FirstPlan user={connectedUser} publicRoute={publicRoute} restriction={restriction} error={error} />
    </>
  )
}
export default LayoutWrapper

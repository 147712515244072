// ** React Imports
import React, { createContext, useContext, } from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import { defaults, } from "conf/app"
// ** Styles
// ** Images

interface AppContextProps {
  readonly name: string
  readonly defaultUrl: string
  readonly offCanvasWidth: string
  readonly needSheetDeadlineDelay: number
}

const AppContext = createContext<AppContextProps>({
  name: "",
  defaultUrl: "",
  offCanvasWidth: "",
  needSheetDeadlineDelay: 0,
})

const useApp = (): AppContextProps => useContext(AppContext)


interface AppProviderProps {
  children: JSX.Element
}

const AppProvider = ({ children, }: AppProviderProps): JSX.Element => {
  const name = defaults.name
  const defaultUrl = defaults.defaultUrl
  const offCanvasWidth = defaults.offCanvasWidth
  const needSheetDeadlineDelay = defaults.needSheetDeadlineDelay

  return (
    <AppContext.Provider value={{
      name,
      defaultUrl,
      offCanvasWidth,
      needSheetDeadlineDelay,
    }}>
      {children}
    </AppContext.Provider>
  )
}

export { useApp, AppProvider, }

// ** React Imports
import { useCallback, useState, } from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Styles
// ** Images


export default function useToggle(initialState: boolean = false): [boolean, () => void, () => void, () => void] {
  const [ isOpen, setIsOpen, ] = useState(initialState)

  const show = useCallback(() => {
    setIsOpen(true)
  }, [])
  const hide = useCallback(() => {
    setIsOpen(false)
  }, [])
  const toggle = useCallback(() => {
    setIsOpen(!isOpen)
  }, [ isOpen, ])

  return [ isOpen, toggle, show, hide, ]
}

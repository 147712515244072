// ** React Imports
// ** Store & Actions
// ** Third Party Components
import {
  useForm,
  Controller,
  // type Resolver,
  type FieldValues,
  type FieldErrorsImpl,
  type Control,
  type SubmitHandler,
  type SubmitErrorHandler,
  type UseFormRegister,
  type UseFormWatch,
  type UseFormReset,
  type UseFormSetValue,
  type UseFormGetValues,
  type UseFormTrigger,
  type DefaultValues,
  type DeepPartial,
} from "react-hook-form"
import { joiResolver, } from "@hookform/resolvers/joi"
import { isSchema, type AnySchema, } from "joi"
// ** Custom Components
// ** Hooks, context & utils
// ** Styles
// ** Images


export default function useReactHookForm<TFormValues extends FieldValues>(
  schema: AnySchema,
  context: object,
  initialValues: DefaultValues<TFormValues>,
  onSubmit: SubmitHandler<TFormValues>,
  onError?: SubmitErrorHandler<TFormValues>
): {
    register: UseFormRegister<TFormValues>
    formState: {
      dirtyFields: Partial<Readonly<Record<string, any>>>
      touchedFields: Partial<Readonly<Record<string, any>>>
      defaultValues?: Readonly<DeepPartial<TFormValues>>
      isSubmitted: boolean
      isSubmitSuccessful: boolean
      submitCount: number
      isValid: boolean
      errors: Partial<FieldErrorsImpl<Record<string, any>>>
    }
    watch: UseFormWatch<TFormValues>
    handleSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
    reset: UseFormReset<TFormValues>
    setValue: UseFormSetValue<TFormValues>
    getValues: UseFormGetValues<TFormValues>
    trigger: UseFormTrigger<TFormValues>
    control: Control<TFormValues, any>
  } {
  if (!isSchema(schema)) throw new Error("Schema type must be ObjectSchema")

  const customMessages = {
    "any.empty": "Entrée requise",
    "any.only": "Entrée non valide",
    "any.required": "Entrée requise",
    "date.base": "Date requise",
    "number.base": "Doit être un nombre",
    "object.base": "Entrée requise",
    "string.email": "Entrée non valide",
    "string.empty": "Entrée requise",
    "string.max": "Entrée non valide",
    "string.min": "Entrée non valide",
    "string.pattern.base": "Entrée non valide",
    "string.required": "Entrée requise",
  }

  const {
    register,
    // unregister,
    formState: {
      // isDirty,
      dirtyFields,
      touchedFields,
      defaultValues,
      isSubmitted,
      isSubmitSuccessful,
      // isSubmitting,
      submitCount,
      isValid,
      // isValidating,
      errors,
    },
    watch,
    handleSubmit,
    reset,
    // resetField,
    // setError,
    // clearErrors,
    setValue,
    // setFocus,
    getValues,
    // getFieldState,
    trigger,
    control,
  } = useForm<TFormValues>({
    defaultValues: initialValues,
    resolver: joiResolver(schema.messages(customMessages)),
    context,
  })

  return {
    register,
    // unregister,
    formState: {
      // isDirty,
      dirtyFields,
      touchedFields,
      defaultValues,
      isSubmitted,
      isSubmitSuccessful,
      // isSubmitting,
      submitCount,
      isValid,
      // isValidating,
      errors,
    },
    watch,
    handleSubmit: handleSubmit(onSubmit, onError),
    reset,
    // resetField,
    // setError,
    // clearErrors,
    setValue,
    // setValues,
    // setFocus,
    getValues,
    // getFieldState,
    trigger,
    control,
  }
}

export { useReactHookForm, Controller, }

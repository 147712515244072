// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
import classnames from "classnames"
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Styles
// ** Images


interface ComponentSpinnerProps {
  classname?: string
}

const ComponentSpinner = (props: ComponentSpinnerProps): JSX.Element => {
  // ** Props
  const {
    classname = null,
  } = props

  return (
    <div className="loading-spinner">
      <div className="loading-spinner">
        <div
          className={classnames("component-container", classname !== null && {
            [classname]: classname,
          })}
        >
          <div className="effect-1"></div>
          <div className="effect-2"></div>
          <div className="effect-3"></div>
        </div>
      </div>
    </div>
  )
}

export default ComponentSpinner

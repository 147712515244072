// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Styles
// ** Images
import logo from "assets/images/logo_sm.png"


const FallbackSpinner = (): JSX.Element => (
  <div className="loading-spinner">
    <div className="fallback-container">
      <img className="loading-logo" src={logo} alt="logo" />
      <div className="effect-1"></div>
      <div className="effect-2"></div>
      <div className="effect-3"></div>
    </div>
  </div>
)

export default FallbackSpinner

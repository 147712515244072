// ** React Imports
import { useState, useCallback, } from "react"
// ** Store & Actions
// ** Third Party Components
import axios, { type ResponseType, type responseEncoding, type Method, } from "axios"
import { toast, } from "react-toastify"
// ** Custom Components
// ** Hooks, context & utils
import { useAuth, } from "utility/context/Auth"
// ** Conf & helpers
import defaultConfig from "conf/api"

// ** Styles
// ** Images

import messages from "conf/messages.json"

type ErrorKeys = keyof typeof messages.error

export type ResponseData = Record<string, unknown> | null
export interface ResponseError {
  message: string
  details?: {
    path: string
    method: string
    previous?: Array<{
      message: string
      code: number
      file: string
      line: number
      trace: Array<{
        file: string
        line: number
        function: string
        class: string
        type: string
      }>
    }>
  }
}
// interface ResponseDebug {
//   clientIP: string
//   path: string
//   method: string
//   vars: Record<string, string>
// }
interface RequestParam {
  url: string
  method?: Method
  parameters?: Record<string, string | number | boolean | Date | Record<string, string | number | boolean>>
  authorization?: string
  timeout?: number
  responseType?: ResponseType
  responseEncoding?: responseEncoding
}
// interface Response {
//   errors: ResponseError
//   data?: ResponseData
//   debug?: ResponseDebug
// }

type Digits = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9"
export type ResponseStatus = `${Digits}${Digits}${Digits}`

export default function useApi(
  handdle: {
    [key in `status${ResponseStatus}`]?: (data: ResponseData, errors: ResponseError[], ...callbackParams: any[]) => void
  } & {
    statusAll?: (data: ResponseData, errors: ResponseError[], status: ResponseStatus, ...callbackParams: any[]) => void
  } & {
    finally?: (...callbackParams: any[]) => void
  }
): {
    data: ResponseData
    errors: ResponseError[]
    status: number | null
    loading: boolean
    request: (params: RequestParam, ...callbackParams: Parameters<any>) => Promise<void>
  } {
  const [ data, setData, ] = useState<ResponseData>(null)
  const [ errors, setErrors, ] = useState<ResponseError[]>([])
  const [ status, setStatus, ] = useState<number | null>(null)
  const [ loading, setLoading, ] = useState(false)
  const { token, } = useAuth()

  const request = useCallback(async (params: RequestParam, ...callbackParams: Parameters<any>): Promise<void> => {
    const {
      url,
      method = "get",
      parameters = {},
      authorization = token.current,
      ...rest
    } = params

    // Transform boolean to numbers, date to timestamp & strigify objects
    Object.keys(parameters).forEach(key => {
      if (typeof parameters[key] === "boolean") parameters[key] = Number(parameters[key])
      else if (parameters[key] instanceof Date) parameters[key] = Math.floor((parameters[key] as Date).getTime() / 1000)
      else if (typeof parameters[key] === "object") parameters[key] = JSON.stringify(parameters[key])
    })

    const config = { ...defaultConfig, ...rest, }

    const requestParams = {
      url,
      method,
      baseURL: config.baseUrl,
      headers: {
        // "Content-Type": (method === "put" || method === "patch") ? "application/x-www-form-urlencoded" : "multipart/form-data",
        "Content-Type": (method === "put" || method === "patch") ? "application/json;charset=utf-8" : "multipart/form-data",
        ...(authorization !== undefined ? { Authorization: authorization, } : null),
        Accept: "application/json",
      },
      ...(method === "get" ? { params: parameters, } : null),
      // ...(method === "post" || method === "put" || method === "delete" || method === "patch"
      ...(method === "post" || method === "delete"
        ? {
          data: Object.keys(parameters).reduce((accumulator, currentValue) => {
            accumulator.append(currentValue, parameters[currentValue] as string)
            return accumulator
          }, new FormData()),
        }
        : null),
      ...(method === "put" || method === "patch" ? { data: parameters, } : null),
      timeout: config.timeout * 1000,
      responseType: config.responseType as ResponseType,
      responseEncoding: config.responseEncoding,
      // validateStatus: status => {
      validateStatus: () => {
        // console.log(`%c Status : ${status}`, "background: blue; color: white")
        return true
      },
    }

    setLoading(true)

    await new Promise((resolve, reject) => {
      axios.request(requestParams)
        .then(response => {
          setErrors(response.data.errors ?? [])
          setData(response.data.data ?? [])
          setStatus(response.status)

          if (response.data.debug !== undefined) {
            // eslint-disable-next-line no-console
            console.log("%c Status : ", "background: #222; color: #bada55", response.status)
            // eslint-disable-next-line no-console
            console.log("%c Debug : ", "background: #222; color: #bada55", response.data.debug)
            // eslint-disable-next-line no-console
            console.log("%c Errors : ", "background: #222; color: #bada55", response.data.errors)
            // eslint-disable-next-line no-console
            console.log("%c Data : ", "background: #222; color: #bada55", response.data.data)
          }
          // console.log(response.data)
          if (response.data.errors.length > 0) {
            response.data.errors.forEach((error: ResponseError) => {
              if (error.message === "tokenBlacklisted" || error.message === "tokenExpired" || error.message === "tokenInvalid") {
                token.current = undefined
              }
              toast.error(messages.error[error.message as ErrorKeys])
            })
          }

          if (response.headers.authorization !== undefined) {
            token.current = response.headers.authorization
          }

          return response
        })
        .then(response => {
          const status = response.status.toString() as ResponseStatus
          // const callbackArgs = [
          //   response.data.data,
          //   response.data.errors ?? [],
          //   ...callbackParams,
          // ]

          handdle[`status${status}`]?.apply(null, [
            response.data.data,
            response.data.errors ?? [],
            ...callbackParams,
          ])
          handdle.statusAll?.apply(null, [
            response.data.data,
            response.data.errors ?? [],
            status,
            ...callbackParams,
          ])
        })
        .catch(error => {
          const errCode = error.code === "ECONNABORTED" ? "commandTimeout" : "apiUnavailable"
          toast.error(messages.error[errCode])

          setErrors([ { message: error.code === "ECONNABORTED" ? "commandTimeout" : "apiUnavailable", }, ])
          setData(null)
          reject(error)
        })
        .finally(() => {
          if (typeof handdle.finally === "function") handdle.finally(callbackParams)
          setLoading(false)
        })
    })
  }, [ axios.request, ])

  return {
    data,
    errors,
    status,
    loading,
    request,
  }
}

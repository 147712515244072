// ** React Imports
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// import { SideBarWidthes, } from "conf/types/Layout"
// ** Styles
// ** Images

// interface ConfigType {
//   leftSideBarType: SideBarWidthes
// }

// add property to change in particular option
// const config: ConfigType = {
//   leftSideBarType: SideBarWidthes.LEFT_SIDEBAR_TYPE_FIXED,
// }


/**
 * Changes the body attribute
 */
export const changeBodyAttribute = (attribute: string, value: string): void => {
  if (document.body !== undefined) document.body.setAttribute(attribute, value)
}

/**
 * Toggle the class on body
 * @param {*} cssClass
 */
export const manageBodyClass = (cssClass: string, action = "toggle"): boolean => {
  switch (action) {
    case "add":
      if (document.body !== undefined) document.body.classList.add(cssClass)
      break
    case "remove":
      if (document.body !== undefined) document.body.classList.remove(cssClass)
      break
    default:
      if (document.body !== undefined) document.body.classList.toggle(cssClass)
      break
  }

  return true
}

// ** React Imports
import React, { Suspense, useEffect, } from "react"
// ** Store & Actions
// ** Third Party Components
import { Outlet, } from "react-router-dom"
import { Container, } from "react-bootstrap"
// ** Custom Components
import ComponentSpinner from "components/spinner/ComponentSpinner"
// ** Hooks, context & utils
import useToggle from "hooks/useToggle"
import { changeBodyAttribute, } from "utility/helpers/layout"
import { useLayout, } from "utility/context/Layout"
// ** Conf & helpers
import { LayoutTypes, SideBarThemes, SideBarWidthes, } from "conf/types/Layout"
// ** Styles
// ** Images


// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(async () => await import("../topbar/Topbar"))
const Navbar = React.lazy(async () => await import("./Navbar"))
const Footer = React.lazy(async () => await import("../Footer"))
const RightSidebar = React.lazy(async () => await import("../RightSidebar"))

const HorizontalLayout = (): JSX.Element => {
  const [ isMenuOpened, toggleMenu, ] = useToggle()
  const {
    layoutWidth,
    disableLayer: {
      isDisabled,
    },
  } = useLayout()

  /**
   * Open the menu when having mobile screen
   */
  const openMenu = (): void => {
    toggleMenu()
    if (document.body !== undefined) {
      if (isMenuOpened) {
        document.body.classList.remove("sidebar-enable")
      } else {
        document.body.classList.add("sidebar-enable")
      }
    }
  }

  /*
   * layout defaults
   */
  useEffect(() => {
    changeBodyAttribute("data-layout", LayoutTypes.LAYOUT_HORIZONTAL)
    changeBodyAttribute("data-leftbar-theme", SideBarThemes.LEFT_SIDEBAR_THEME_DEFAULT)
    changeBodyAttribute("data-leftbar-compact-mode", SideBarWidthes.LEFT_SIDEBAR_TYPE_FIXED)
  }, [])

  useEffect(() => {
    changeBodyAttribute("data-layout-mode", layoutWidth)
  }, [ layoutWidth, ])

  return (
    <div className="wrapper">
      {isDisabled && <ComponentSpinner classname="size-sm" />}
      <div className="content-page">
        <div className="content">
          <Suspense fallback={<ComponentSpinner />}>
            <Topbar
              openLeftMenuCallBack={openMenu}
              navCssClasses="topnav-navbar topnav-navbar-dark"
              topbarDark={true}
            />
          </Suspense>

          <Suspense fallback={<ComponentSpinner />}>
            <Navbar isMenuOpened={isMenuOpened} />
          </Suspense>

          <Container fluid>
            <Outlet />
          </Container>
        </div>

        <Suspense fallback={<ComponentSpinner />}>
          <Footer />
        </Suspense>

        <Suspense fallback={<ComponentSpinner />}>
          <RightSidebar />
        </Suspense>
      </div>
    </div>
  )
}

export default HorizontalLayout

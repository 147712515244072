// ** React Imports
import React, { useState, createContext, useContext, } from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
import useToggle from "hooks/useToggle"
// ** Conf & helpers
import type { LayoutColors, LayoutTypes, LayoutWidthes, SideBarThemes, SideBarWidthes, } from "conf/types/Layout"
import { defaults, } from "conf/layout"
import { manageBodyClass, } from "utility/helpers/layout"
// ** Styles
// ** Images

interface LayoutContextProps {
  readonly layoutType: LayoutTypes
  readonly layoutColor: LayoutColors
  readonly layoutWidth: LayoutWidthes
  readonly leftSideBarTheme: SideBarThemes
  readonly leftSideBarType: SideBarWidthes
  readonly rightSidebarVisible: boolean
  readonly showRightSidebar: () => void
  readonly hideRightSidebar: () => void
  readonly changeLayout: (layout: LayoutTypes) => void
  readonly changeLayoutColor: (color: LayoutColors) => void
  readonly changeLayoutWidth: (width: LayoutWidthes) => void
  readonly changeSidebarTheme: (sidebarTheme: SideBarThemes) => void
  readonly changeSidebarType: (sidebarType: SideBarWidthes) => void
  readonly reset: () => void
  readonly disableLayer: {
    isDisabled: boolean
    toggle: () => void
    show: () => void
    hide: () => void
  }
}

const LayoutContext = createContext<LayoutContextProps>({
  layoutType: defaults.layoutType,
  layoutColor: defaults.layoutColor,
  layoutWidth: defaults.layoutWidth,
  leftSideBarTheme: defaults.leftSideBarTheme,
  leftSideBarType: defaults.leftSideBarType,
  rightSidebarVisible: defaults.rightSidebarVisible,
  showRightSidebar: () => {},
  hideRightSidebar: () => {},
  changeLayout: () => {},
  changeLayoutColor: () => {},
  changeLayoutWidth: () => {},
  changeSidebarTheme: () => {},
  changeSidebarType: () => {},
  reset: () => {},
  disableLayer: {
    isDisabled: false,
    toggle: () => {},
    show: () => {},
    hide: () => {},
  },
})

const useLayout = (): LayoutContextProps => useContext(LayoutContext)


interface LayoutProviderProps {
  children: JSX.Element
}

const LayoutProvider = ({ children, }: LayoutProviderProps): JSX.Element => {
  const [ layoutType, setLayoutType, ] = useState<LayoutTypes>(defaults.layoutType)
  const [ layoutColor, setLayoutColor, ] = useState<LayoutColors>(defaults.layoutColor)
  const [ layoutWidth, setLayoutWidth, ] = useState<LayoutWidthes>(defaults.layoutWidth)
  const [ leftSideBarTheme, setLeftSideBarTheme, ] = useState<SideBarThemes>(defaults.leftSideBarTheme)
  const [ leftSideBarType, setLeftSideBarType, ] = useState<SideBarWidthes>(defaults.leftSideBarType)
  const [ rightSidebarVisible, setRightSidebarVisible, ] = useState<boolean>(defaults.rightSidebarVisible)

  const changeLayout = (layout: LayoutTypes): void => { setLayoutType(layout) }
  const changeLayoutColor = (color: LayoutColors): void => { setLayoutColor(color) }
  const changeLayoutWidth = (width: LayoutWidthes): void => { setLayoutWidth(width) }
  const changeSidebarTheme = (sidebarTheme: SideBarThemes): void => { setLeftSideBarTheme(sidebarTheme) }
  const changeSidebarType = (sidebarType: SideBarWidthes): void => { setLeftSideBarType(sidebarType) }

  const [ isOpen, toggle, show, hide, ] = useToggle()

  const showRightSidebar = (): void => {
    setRightSidebarVisible(true)
    manageBodyClass("end-bar-enabled", "add")
  }

  const hideRightSidebar = (): void => {
    setRightSidebarVisible(true)
    manageBodyClass("end-bar-enabled", "remove")
  }

  const reset = (): void => {
    setLayoutType(defaults.layoutType)
    setLayoutColor(defaults.layoutColor)
    setLayoutWidth(defaults.layoutWidth)
    setLeftSideBarTheme(defaults.leftSideBarTheme)
    setLeftSideBarType(defaults.leftSideBarType)
  }

  return (
    <LayoutContext.Provider value={{
      layoutType,
      layoutColor,
      layoutWidth,
      leftSideBarTheme,
      leftSideBarType,
      rightSidebarVisible,
      showRightSidebar,
      hideRightSidebar,
      changeLayout,
      changeLayoutColor,
      changeLayoutWidth,
      changeSidebarTheme,
      changeSidebarType,
      reset,
      disableLayer: {
        isDisabled: isOpen,
        toggle,
        show,
        hide,
      },
    }}>
      {children}
    </LayoutContext.Provider>
  )
}

export { useLayout, LayoutProvider, }

// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
import { Link, } from "react-router-dom"
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Styles
// ** Images

const ErrorPageNotFound = (): JSX.Element => {
  return (
    <div className="text-center">
      <h1 className="text-error">
        4<i className="mdi mdi-emoticon-sad"></i>4
      </h1>
      <h4 className="text-uppercase text-danger mt-3">Page Non Trouvée</h4>
      <p className="text-muted mt-3">
        Il semble que vous ayez pris un mauvais chemin. Pas de panique, cela arrive aux meilleurs.
      </p>

      <Link className="btn btn-info mt-3" to="/">
        <i className="mdi mdi-reply"></i> Retour à l&apos;accueil
      </Link>
    </div>
  )
}

export default ErrorPageNotFound

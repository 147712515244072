// ** React Imports
import React, { } from "react"
// ** Store & Actions
// ** Third Party Components
import { Form, InputGroup, } from "react-bootstrap"
import { type FieldErrors, type Control, } from "react-hook-form"
import classNames from "classnames"
// ** Custom Components
// ** Hooks, context & utils
import useToggle from "hooks/useToggle"
// ** Conf & helpers
// ** Styles
// ** Images


interface PasswordInputProps {
  name: string
  placeholder?: string
  _ref?: React.MutableRefObject<undefined>
  // refCallback?: any
  errors?: FieldErrors
  control?: Control<any>
  register?: any
  className?: string
  autoComplete?: string
}

/* Password Input */
const PasswordInput = ({
  name,
  placeholder,
  _ref,
  // refCallback,
  errors,
  register,
  className,
  autoComplete,
}: PasswordInputProps): JSX.Element => {
  const [ showPassword, togglePassword, ] = useToggle()

  return (
    <InputGroup className="mb-0">
      <Form.Control
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        name={name}
        id={name}
        as="input"
        _ref={_ref}
        // ref={(r: HTMLInputElement) => {
        //   if (refCallback !== undefined) refCallback(r)
        // }}
        className={className}
        isInvalid={errors?.[name] !== undefined}
        {...(register !== undefined ? register(name) : {})}
        autoComplete={autoComplete ?? name}
      />
      <div
        className={classNames("input-group-text", "input-group-password", {
          "show-password": showPassword,
        })}
        data-password={showPassword ? "true" : "false"}
      >
        <span className="password-eye" onClick={togglePassword}></span>
      </div>
    </InputGroup>
  )
}

export default PasswordInput

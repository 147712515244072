// ** React Imports
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Styles
// ** Images


export const defaults = {
  logoutDelay: 900000, // Milliseconds
}

// ** React Imports
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import { defaults, } from "./app"
// ** Styles
// ** Images

const config = {
  authSessionKey: `${defaults.name}_user`,
  // baseUrl: "https://api.gemacom.fr",
  baseUrl: "http://dom.perktek.com:8080",
  // baseUrl: "http://hyper.vm-01",
  timeout: 10,
  responseType: "json",
  responseEncoding: "utf8",
  publicKey: "-----BEGIN PUBLIC KEY-----\n" +
             "MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA1w0JC7nOL9xtLHVkHZD7\n" +
             "vGgBGEghHRSHj3B4Lv7NihkjqGYN/GLPCHIu6yF/lbdoI7E9odNRVNrv2qiVv62o\n" +
             "+ZSNaD3oNKfAZTBFfKGvZDUCx/uAsqd0NiJqiPiKJLUa3eCWLeACVsTRWU01bYiy\n" +
             "gnQZwwX3HUNpbPtZE1qnk0lFrcFZNsiiX94D55PF6L85q/Z4Xz+zBprS1r/Mr1Fi\n" +
             "e4o+QWjJg98Ba16hx/emH5LBjCXmd9pccx7ciNGFgULsX3O8MlvhiMq01wDP7yON\n" +
             "kaHiGCRmzJiJc3I0g3FSum7Wp27Dl9sYZTdU0zAWRt72FylLTCv83W1kg7PU/8QH\n" +
             "sc4rfKS7nTVBn1DiKV1qTGkdKyK2SSwK24955c1+OBK0Zlhqt4BIKbgzl6qRJcES\n" +
             "H6FemO8H/WlA8ui/ovrSe8p9x3ACARq0QGv5V7MHoOajXC46v/Z1t96vVoslnOx5\n" +
             "U0E+trtQQbM9vVuTEnbENpQH9mhPSUVT71+JLHuolilUS38vTOtHXA3vCwp2yaKP\n" +
             "ooh0QVTu3jx/PH3ZvoegWcuRIORXM5pvcf4A7zDZUG61+sUSHhlx0knaejkLMX9M\n" +
             "3zgRJyzyf+T57A8580PdeW6lnKpI2crLna+joZ8xUraLwstq2Gp7/3JArXIaQrIM\n" +
             "6Orn924v60gl/DpchNK2wCMCAwEAAQ==\n" +
             "-----END PUBLIC KEY-----\n",
}

export default config

// ** React Imports
import React, { } from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
import { useApp, } from "utility/context/App"
// ** Conf & helpers
// ** Styles
import "assets/scss/Saas.scss"
// import "assets/scss/Creative.scss"
// import "assets/scss/Modern.scss"
// ** Images

import Router from "router/Router"

const App = (): JSX.Element => {
  const { name: appName, } = useApp()

  document.title = appName

  return <Router />
}

export default App
